import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

// Psikotest
import PSSnackbarProvider from "./context/psikotest/SnackbarProvider";
import PSAssessmentProvider from "./context/psikotest/PPAssessmentProvider";
import PSAuthProvider from "./context/psikotest/AuthProvider";
//import PSWebSocketProvider from "./context/psikotest/WebSocketProvider";
import PSLoaderProvider from "./context/psikotest/LoaderProvider";

// Assessment
import ASSnackbarProvider from "./context/assessment/SnackbarProvider";
import ASAuthProvider from "./context/assessment/AuthProvider";
import ASAssessmentProvider from "./context/assessment/AssessmentProvider";
//import ASWebSocketProvider from "./context/assessment/WebSocketProvider";
import ASLoaderProvider from "./context/assessment/LoaderProvider";

// Websocket
import WebSocketProvider from "./context/WebSocketProvider";


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <PSSnackbarProvider>
        <ASSnackbarProvider>
          <PSAuthProvider>
            <ASAuthProvider>
              <PSAssessmentProvider>
                <ASAssessmentProvider>
                  <WebSocketProvider >
                    {/** <PSWebSocketProvider> */}
                    {/** <ASWebSocketProvider> */}
                    <PSLoaderProvider>
                      <ASLoaderProvider>
                        <App />
                      </ASLoaderProvider>
                    </PSLoaderProvider>
                    {/** </ASWebSocketProvider> */}
                    {/** </PSWebSocketProvider> */}
                  </WebSocketProvider>
                </ASAssessmentProvider>
              </PSAssessmentProvider>
            </ASAuthProvider>
          </PSAuthProvider>
        </ASSnackbarProvider>
      </PSSnackbarProvider>
    </BrowserRouter>
  </React.StrictMode >,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
