import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";

// Contexts
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";

// Helpers
import errorHandler from "../../../helper/assessment/errorHandler";

// Controllers
import LevelAssessment from "../../../controller/assessment/assessment_system/level_assessment";

// Type
type FormValues = {
  category: TLevelAssessmentCategory[];
};

// for max length of formValues
const maxCategory = 4;

const schema = yup.object().shape({
  category: yup.array().of(
    yup.object().shape({
      level_assessment_id: yup.number().transform((currValue, oldValue) => {
        return oldValue === "" ? undefined : currValue;
      }),
      category_id: yup.number().transform((currValue, oldValue) => {
        return oldValue === "" ? undefined : currValue;
      }),
      level_index: yup.number(),
    })
  ),
});

function FormCategory(props: {
  doc: TLevelAssessment;
  isCreate: boolean;
  level_assessment_id: string;
  categoryOptions: TSelect[];
}) {
  // call message setter
  const { setMessage } = useContext(LoaderContext);
  // call notif setter
  const { setNotif } = useContext(SnackbarContext);
  // helper state
  const [editing, setEditing] = useState<boolean>(false);
  // react hook form
  const { register, control, handleSubmit, setValue, reset } = useForm<FormValues>({
    defaultValues: { category: [] },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    name: "category",
    control,
    keyName: "ids",
  });

  const getDoc = useCallback(
    async function getDoc() {
      try {
        if (!props.isCreate) {
          // create object constructor LevelAssessment controller
          const la = new LevelAssessment();
          setMessage("Fetch Level Assessment Session");
          const resCategoryList = await la.listCategory(props.level_assessment_id);
          // setValue not work in here
          if (resCategoryList.data.list.length > 0) {
            reset({ category: resCategoryList.data.list });
          }
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, props.level_assessment_id, props.isCreate, reset]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit = (data: FormValues) => {
    setEditing(false);
    handleChange(data.category);
  };

  const handleChange = (categories: TLevelAssessmentCategory[]) => {
    categories.forEach(async (category, i) => {
      const categoryPrev = fields[i];
      const categoryCurr = category;
      // make sure changed occured
      const isChanged =
        categoryPrev.category_id !== categoryCurr.category_id || categoryPrev.level_index !== categoryCurr.level_index;
      if (isChanged) {
        // saving in update with empty category
        try {
          setMessage("Save Level Assessment Category");
          const la = new LevelAssessment();
          // check if have id
          if (category.id) {
            await la.updateCategory(category);
            // copy list category
            let newCategories = [...categories];
            // update id from create result to current category[current index]
            newCategories[i] = {
              ...newCategories[i],
              category_id: category.category_id,
            };
            // update formValues
            setValue("category", newCategories);
          } else {
            // add new category
            const resCreate = await la.addCategory(props.level_assessment_id, category);
            // copy list category
            let newCategories = [...categories];
            // update id from create result to current category[current index]
            newCategories[i] = {
              ...newCategories[i],
              id: resCreate.data.saved_id,
            };
            // update formValues
            setValue("category", newCategories);
          }
          // reset message and loader
          setMessage("");
        } catch (error) {
          setMessage("");
          const errorMessage = errorHandler(error);
          setNotif({ type: "error", message: errorMessage });
        }
      }
    });
  };

  const handleRemove = async (i: number) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        // to reset button save if remove new item without submit
        setEditing(false);
        // show remove message and loader
        setMessage("Remove Level Assessment Category");
        // create object constructor LevelAssessment controller
        const la = new LevelAssessment();
        // get the category id
        const removeCategoryId = fields[i].id;
        // if id send delete request to rest
        if (removeCategoryId) {
          await la.deleteCategory(removeCategoryId.toString());
        }
        // remove from list
        remove(i);
        // reset mesage and loader
        setMessage("");
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div>
      <div className="w-full md:w-full mb-2">
        {fields.length < maxCategory && !editing && (
          <button
            onClick={() =>
              append({
                level_assessment_id: parseInt(props.level_assessment_id),
                category_id: 0,
              })
            }
            className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Category
            </div>
          </button>
        )}
        {editing && (
          <button
            type="button"
            className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Save
            </div>
          </button>
        )}
      </div>
      <div className="w-full mb-1 hidden md:flex">
        <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white text-center">No</div>
        <div className="w-full md:w-11/12 px-3 py-2 border rounded bg-slate-600 text-white">Category</div>
        <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 text-white text-center">Remove</div>
      </div>
      <form onBlur={() => handleSubmit(onSubmit)()}>
        {fields.map((field, i) => {
          return (
            <div key={`session_${i}`} className="w-full mb-1 md:flex">
              <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white md:text-center">
                {i + 1}
              </div>
              <input {...register(`category.${i}.level_index`)} value={i + 1} type="number" className="hidden" />
              <div className="w-full md:w-11/12 border-x border-white">
                <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                  <select
                    className="appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                    onFocus={() => setEditing(true)}
                    {...register(`category.${i}.category_id`)}
                  >
                    {props.categoryOptions.map((v, i) => (
                      <option key={i} value={v.id}>
                        {v.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <button
                type="button"
                className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 hover:bg-red-700 text-white text-center cursor-pointer disabled:bg-slate-500"
                onClick={() => handleRemove(i)}
                disabled={fields.length === 1}
              >
                Remove
              </button>
            </div>
          );
        })}
      </form>
    </div>
  );
}

export default FormCategory;
