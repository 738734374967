import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon, ArrowDownTrayIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

// Context
import AuthContext from "../../../context/assessment/AuthContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";
import WebSocketContext from "../../../context/WebSocketContext";

// Controller
import Assessment from "../../../controller/assessment/assessment_system/assessment";
import LevelAssessment from "../../../controller/assessment/assessment_system/level_assessment";
import Company from "../../../controller/assessment/assessment_system/company";

// Helper
import errorHandler from "../../../helper/assessment/errorHandler";

type FormValues = {
  session: TAssessmentSession[];
};

const maxSession = 4;

const schema = yup.object().shape({
  session: yup.array().of(
    yup.object().shape({
      category_id: yup.number().required(),
      questionary_id: yup.number().required(),
    })
  ),
});

function FormSession(props: {
  doc: TAssessment;
  isCreate: boolean;
  assessment_id: string;
  editable: boolean;
  categoryOptions: TSelect[];
  questionaryOptions: TSelect[];
  assessorOptions: TSelect[];
  setIsOpenParticipantSessionPanel: (open: TAssessmentSession) => void;
}) {
  // context
  const { user } = useContext(AuthContext);
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  const { sendMessage } = useContext(WebSocketContext)
  // is admin
  const isAdmin = user.roles === "1";
  // is pic assessment
  const isPicAssessment = props.doc.pic_id.toString() === user.assessor_id;
  // helper state
  const [editing, setEditing] = useState<boolean>(false);
  const [companySession, setCompanySession] = useState<Array<TLevelAssessmentCategory>>([]);
  // react hook form
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<FormValues>({
    defaultValues: { session: [] },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    name: "session",
    control,
    keyName: "ids",
  });
  const watchFieldArray = watch("session");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const getDoc = useCallback(
    async function getDoc() {
      try {
        if (!props.isCreate) {
          // fetch session list
          setMessage("Fetch Assessment Session");
          const ac = new Assessment();
          const resSessionList = await ac.listSession(props.assessment_id);
          // setValue not work in here
          // if avaible we don't need get data from company setup
          if (resSessionList.data.list.length > 0) {
            // sort by level index in assessment level category
            resSessionList.data.list.sort((a: TAssessmentSession, b: TAssessmentSession) => a?.level_index - b?.level_index);
            reset({ session: resSessionList.data.list });
          } else {
            // if not set let's get data from company setup
            // get company setup
            setMessage("Fetch Company Detail");
            const cc = new Company();
            const resCompanyDetail = await cc.get(props.doc.company_id);
            const company: TCompany = resCompanyDetail.data.row;
            // if draft check session for company id setup
            setMessage("Fetch Company Level Assessment");
            const lac = new LevelAssessment();
            const resLevelAssessment = await lac.get(company.level_assessment_id.toString());
            const levelAssessment: TLevelAssessment = resLevelAssessment.data.row;
            // 
            const resLevelAssessmentCategory = await lac.listCategory(levelAssessment.id.toString());
            setCompanySession(resLevelAssessmentCategory.data.list);
          }
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, props.assessment_id, props.isCreate, reset, props.doc.company_id]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  useEffect(() => {
    // update if company session setup is avaible
    if (companySession.length > 0) {
      companySession.forEach((session) => {
        append({
          category_id: session.category_id,
          assessment_id: parseInt(props.assessment_id),
          open: "0",
        });
      });
    }
  }, [companySession, append, props.assessment_id]);

  const onSubmit = (data: FormValues) => {
    setEditing(false);
    handleChange(data.session);
  };

  const handleChange = (sessions: TAssessmentSession[]) => {
    sessions.forEach(async (session, i) => {
      const sessionPrev = { ...fields[i] };
      const sessionCurr = { ...session };
      // make sure changed occured
      // make sure questionary id is not 0
      const isChanged =
        (sessionPrev?.category_id !== sessionCurr.category_id ||
          sessionPrev?.questionary_id !== sessionCurr.questionary_id) && sessionCurr.questionary_id !== 0;
      if (isChanged) {
        // saving in update with empty questionary
        try {
          setMessage("Save Session");
          const ac = new Assessment();
          // check if have id
          if (session.id) {
            // when category changed quetionary is changed by default form select not tracked
            // lets get the first questionary of selected by default
            if (sessionPrev?.questionary_id === sessionCurr.questionary_id) {
              const listQuestionByCategory = props.questionaryOptions.filter((v) => v.unique_key?.toString() === sessionCurr.category_id?.toString())
              if (listQuestionByCategory.length > 0) {
                if (listQuestionByCategory[0].id !== "" && typeof listQuestionByCategory[0].id === "number") {
                  session.questionary_id = listQuestionByCategory[0].id;
                }
              }
            }
            await ac.updateSession(session);
            let newSessions = [...sessions];
            newSessions[i] = {
              ...newSessions[i],
              assessment_id: session.assessment_id,
              questionary_id: session.questionary_id
            };
            setValue("session", newSessions);
          } else {
            // create new session
            const resCreate = await ac.addSession(props.assessment_id, session);
            let newSessions = [...sessions];
            newSessions[i] = {
              ...newSessions[i],
              id: resCreate.data.saved_id,
            };
            setValue("session", newSessions);
          }
          setMessage("");
        } catch (error) {
          setMessage("");
          const errorMessage = errorHandler(error);
          setNotif({ type: "error", message: errorMessage });
        }
      }
    });
  };

  const handleRemove = async (i: number) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        // to reset button save if remove new item without submit
        setEditing(false);
        // start remove
        setMessage("Remove Session");
        const ac = new Assessment();
        const removeSessionId = fields[i].id;
        if (removeSessionId) {
          await ac.deleteSession(removeSessionId.toString());
        }
        remove(i);
        setMessage("");
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const handleToggleOpen = async (i: number) => {
    try {
      setMessage("Update Status Session");
      const ac = new Assessment();
      const copySession = getValues("session");
      const updateSession = {
        ...copySession[i],
        open: copySession[i].open === "0" ? "1" : "0",
      };
      await ac.updateSession(updateSession);
      copySession.splice(i, 1, updateSession);
      setValue("session", copySession);
      setMessage("");
      // broadcast message to all client (target: participant)
      sendMessage("assessment", JSON.stringify({
        target: "participant",
        action: "check open session",
        message: updateSession.open === "1" ? "Assessment open by PIC" : "Assessment close by PIC"
      }))
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div>
      {props.editable && isAdmin && (
        <div className="w-full md:w-full mb-2">
          {fields.length < maxSession && !editing && (
            <button
              onClick={() =>
                append({
                  assessment_id: parseInt(props.assessment_id),
                  questionary_id: 0,
                  open: "0",
                })
              }
              className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                Session
              </div>
            </button>
          )}
          {editing && (
            <button
              type="button"
              className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                Save
              </div>
            </button>
          )}
        </div>
      )}
      <div className="w-full mb-1 hidden md:flex">
        <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white text-center">No</div>
        <div className="w-full md:w-5/12 px-3 py-2 border rounded bg-slate-600 text-white">Category</div>
        <div className="w-full md:w-5/12 px-3 py-2 border rounded bg-slate-600 text-white">Questionary</div>
        {props.doc.status === "1" && (
          <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-500 text-white text-center">Status</div>
        )}
        {props.doc.status === "1" && (
          <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-blue-500 text-white text-center">Participant</div>
        )}
        {props.editable && isAdmin && (
          <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 text-white text-center">Remove</div>
        )}
      </div>
      <form onBlur={() => handleSubmit(onSubmit)()}>
        {controlledFields.map((field, i) => {
          return (
            <div key={`session_${i}`} className="w-full mb-1 md:flex">
              <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white md:text-center">
                {i + 1}
              </div>
              <div className="w-full md:w-5/12 border-x border-white">
                <select
                  className={
                    (errors?.session?.[i]?.category_id ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                  }
                  disabled={!isAdmin}
                  onFocus={() => setEditing(true)}
                  {...register(`session.${i}.category_id`)}
                >
                  <option value="" disabled>
                    Please Select Category
                  </option>
                  {props.categoryOptions.map((v, i) => (
                    <option key={i} value={v.id}>
                      {v.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md:w-5/12 border-x border-white">
                <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                  <select
                    className={
                      (errors?.session?.[i]?.questionary_id
                        ? "border-red-500"
                        : "border-gray-200 focus:border-gray-500") +
                      " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                    }
                    disabled={!isAdmin}
                    onFocus={() => setEditing(true)}
                    {...register(`session.${i}.questionary_id`)}
                  >
                    <option value="" disabled>
                      Please Select Questionary
                    </option>
                    {props.questionaryOptions
                      .filter((v) => v.unique_key?.toString() === field.category_id?.toString())
                      .map((v, i) => (
                        <option key={i} value={v.id}>
                          {v.label}
                        </option>
                      )
                      )}
                  </select>
                </div>
              </div>
              {props.doc.status === "1" && (
                <>
                  {field.category === "Role Playing" ?
                    <button
                      type="button"
                      className={"flex justify-center w-full md:w-1/12 px-3 py-2 border rounded text-white cursor-no-drop bg-slate-600"}
                      onClick={() => handleToggleOpen(i)}
                      disabled={true}
                    >
                      <ArrowRightIcon className="w-5 h-5 stroke-white fill-tranparent" aria-hidden="true" />
                    </button> :
                    <button
                      type="button"
                      className={
                        (field.open === "0" ? "bg-slate-500 hover:bg-slate-700" : "bg-green-500 hover:bg-green-700") +
                        " w-full md:w-1/12 px-3 py-2 border rounded text-white text-center cursor-pointer disabled:bg-slate-500"
                      }
                      onClick={() => handleToggleOpen(i)}
                      disabled={props.doc.status !== "1" || (!isAdmin && !isPicAssessment)}
                    >
                      {field.open === "0" ? "Closed" : "Open"}
                    </button>
                  }</>
              )}
              {props.doc.status === "1" && (
                <button
                  type="button"
                  className={"bg-blue-500 hover:bg-blue-700 w-full md:w-1/12 px-3 py-2 border rounded text-white text-center cursor-pointer disabled:bg-slate-500"}
                  onClick={() => props.setIsOpenParticipantSessionPanel(field)}
                >
                  Participant
                </button>
              )}
              {props.editable && isAdmin && (
                <button
                  type="button"
                  className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 hover:bg-red-700 text-white text-center cursor-pointer disabled:bg-slate-500"
                  onClick={() => handleRemove(i)}
                  disabled={fields.length === 1}
                >
                  Remove
                </button>
              )}
            </div>
          );
        })}
      </form>
    </div>
  );
}

export default FormSession;
