import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";

// Icons
import { PlusIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";

// Contexts
import SnackbarContext from "../../../context/assessment/SnackbarContext";
import LoaderContext from "../../../context/assessment/LoaderContext";
import errorHandler from "../../../helper/assessment/errorHandler";

import Company from "../../../controller/assessment/assessment_system/company";

type FormValues = {
  competency: TCompanyCompetency[];
};

const maxCompetency = 20;

const schema = yup.object().shape({
  competency: yup.array().of(
    yup.object().shape({
      competency: yup.string().label("Competency").required(),
    })
  ),
});

function FormCompetency(props: { isCreate: boolean; company_id: string; editable: boolean }) {
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // helper state
  const [editing, setEditing] = useState<boolean>(false);
  // react hook form
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormValues>({
    defaultValues: { competency: [] },
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { fields, append, remove } = useFieldArray({
    name: "competency",
    control,
    keyName: "ids",
  });

  const getDoc = useCallback(
    async function getDoc() {
      try {
        if (!props.isCreate) {
          // fetch competency list
          const cc = new Company();
          setMessage("Fetch Company Competency");
          const resCompetencyList = await cc.listCompetency(props.company_id);
          // setValue not work in here
          if (resCompetencyList.data.list.length > 0) {
            reset({ competency: resCompetencyList.data.list });
          }
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    },
    [setNotif, setMessage, props.company_id, props.isCreate, reset]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit = (data: FormValues) => {
    setEditing(false);
    handleChange(data.competency);
  };

  const handleChange = (competencies: TCompanyCompetency[]) => {
    competencies.forEach(async (competency, i) => {
      try {
        const competencyPrev = fields[i];
        const competencyCurr = competency;
        // make sure changed occured
        const isChanged = competencyPrev.competency !== competencyCurr.competency;
        if (isChanged) {
          setMessage("Save Score Name");
          const cc = new Company();
          // check if have id
          if (competency.id) {
            await cc.updateCompetency(competency);
          } else {
            // create new competency
            const resCreate = await cc.addCompetency(props.company_id, competency);
            let newCompetencies = [...competencies];
            newCompetencies[i] = {
              ...newCompetencies[i],
              id: resCreate.data.saved_id,
            };
            setValue("competency", newCompetencies);
          }
          setMessage("");
        }
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
      }
    });
  };

  const handleRemove = async (i: number) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        // to reset button save if remove new item without submit
        setEditing(false);
        // start remove
        setMessage("Remove Competency");
        const cc = new Company();
        const removeCompetencyId = fields[i].id;
        if (removeCompetencyId) {
          await cc.deleteCompetency(removeCompetencyId.toString());
        }
        remove(i);
        setMessage("");
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  return (
    <div className="pb-[40rem]">
      {fields.length < maxCompetency && props.editable && (
        <div className="w-full md:w-full mb-2">
          {!editing && (
            <button
              type="button"
              onClick={() => {
                append({
                  competency: "",
                });
              }}
              className="block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                <PlusIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                Competency
              </div>
            </button>
          )}
          {editing && (
            <button
              type="button"
              className="block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
            >
              <div className="flex">
                <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
                Save
              </div>
            </button>
          )}
        </div>
      )}
      <div className="w-full mb-1 hidden md:flex">
        <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white text-center">No</div>
        <div className="w-full md:w-11/12 px-3 py-2 border rounded bg-slate-600 text-white">Competency</div>
        {props.editable && (
          <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 text-white text-center">Remove</div>
        )}
      </div>
      <form onBlur={() => handleSubmit(onSubmit)()}>
        {fields.map((competency, i) => {
          return (
            <div key={`competency_${i}`} className="w-full mb-1 md:flex">
              <div className="w-full md:w-1/12 px-3 py-2 border rounded bg-slate-600 text-white md:text-center">
                {i + 1}
              </div>
              <div className="w-full md:w-11/12 border-x border-white">
                <input
                  className={
                    (errors?.competency?.[i]?.competency ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                    " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                  }
                  disabled={!props.editable}
                  onFocus={() => setEditing(true)}
                  {...register(`competency.${i}.competency`)}
                />
              </div>
              {props.editable && (
                <button
                  type="button"
                  className="w-full md:w-1/12 px-3 py-2 border rounded bg-red-500 hover:bg-red-700 text-white text-center cursor-pointer disabled:bg-slate-500"
                  onClick={() => handleRemove(i)}
                  disabled={fields.length === 1}
                >
                  Remove
                </button>
              )}
            </div>
          );
        })}
      </form>
    </div>
  );
}

export default FormCompetency;
