import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import Swal from "sweetalert2";

// Icon

// Context
import PPAssessmentContext from "../../../context/psikotest/PPAssessmentContext";
import SnackbarContext from "../../../context/psikotest/SnackbarContext";
import WebSocketContext from "../../../context/WebSocketContext";

// Controller
import ParticipantSession from "../../../controller/psikotest/psikotest_participant/session";

// Helper
import logRender from "../../../helper/psikotest/logRender";
import errorHandler from "../../../helper/psikotest/errorHandler";

// Component
import Header from "../../../components/psikotest/psikotest_participant/Header";
// Intelligence
import SubtestCfit from "../../../components/psikotest/psikotest_participant/SubtestCfit";
import SubtestIst from "../../../components/psikotest/psikotest_participant/SubtestIst";
// Personality
import SubtestMbti from "../../../components/psikotest/psikotest_participant/SubtestMbti";
import SubtestPapi from "../../../components/psikotest/psikotest_participant/SubtestPapi";
import SubtestEpps from "../../../components/psikotest/psikotest_participant/SubtestEpps";
import SubtestSrq20 from "../../../components/psikotest/psikotest_participant/SubtestSrq20";
// Behavioural
import SubtestRac from "../../../components/psikotest/psikotest_participant/SubtestRac";
import SubtestDisc from "../../../components/psikotest/psikotest_participant/SubtestDisc";
import SubtestResilience from "../../../components/psikotest/psikotest_participant/SubtestResilience";

function Session() {
  // Context
  const { assessment_participant, timeRemaining, setTimeRemaining } = useContext(PPAssessmentContext);
  const { setNotif } = useContext(SnackbarContext);
  const { socket } = useContext(WebSocketContext)
  // Ref
  const webcamRef = useRef<Webcam>(null);
  // State
  const [assessment_participant_session, setAssessmentParticipantSession] = useState<TPPAssessmentParticipantSession | undefined>(undefined);
  const [questionaryWithImageLoad, setQuestionaryWithImageLoad] = useState<boolean | undefined>(undefined);
  const [questionaryImageLoadFailed, setQuestionaryImageLoadFailed] = useState<string>("");
  const [imageQuestionaryTotal, setImageQuestionaryTotal] = useState<number>(0);
  const [imageQuestionaryTotalLoaded, setImageQuestionaryTotalLoaded] = useState<number>(0);
  // for state assessmnet is closed by user
  const [close_by_user, setCloseByUser] = useState<boolean>(false);

  // State UNCHECK
  const [printScreen, setPrintScreen] = useState<boolean>(false);
  const [controlKeyPressed, setControlKeyPressed] = useState<boolean>(false);
  const [isCheckActiveSession, setIsCheckActiveSession] = useState<boolean>(false);
  const [isCheckCamera, setIsCheckCamera] = useState<boolean>(false);
  const [cameraAllowed, setCameraAllowed] = useState<boolean>(false);
  const [captureImage, setCaptureImage] = useState<string>();
  // screencap every x second = default 5 minutes
  const [intervalGetImage, setIntervalGetImage] = useState<number>(300);
  // interval check timer x second = default 105 second
  const intervalCheckTimer = 105;

  // socket event
  socket.onmessage = (event) => {
    //console.log(event.data)
    if (typeof event.data === "string") {
      const wsdata = JSON.parse(JSON.parse(event.data)?.body ?? "");
      const scope = wsdata?.scope;
      const target = wsdata?.target;
      const action = wsdata?.action;
      const message = wsdata?.message;
      if (scope === "psikotest" && target === "participant") {
        if (action === "check open session") {
          // set questionary image load to undefined
          setQuestionaryWithImageLoad(undefined)
          // get open subtest
          getOpenSubtest();
        }
        if (message) {
          setNotif({ type: "info", message: message });
        }
      }
    }
  };

  // disable print screen
  window.addEventListener("keyup", (event) => {
    if (event.key === "PrintScreen") {
      event.preventDefault()
      // hide display
      setPrintScreen(true);
      // make dummy copy display
      var aux = document.createElement("input");
      aux.setAttribute("value", "print screen disabled!");
      document.body.appendChild(aux);
      aux.select();
      if (!navigator.clipboard) {
        document.execCommand("copy");
      } else {
        navigator.clipboard.writeText("print screen disabled!").then().catch();
      }
      document.body.removeChild(aux);
      // revert display
      setTimeout(() => {
        setPrintScreen(false);
      }, 1000);
    }
    if (event.key === "Control") {
      event.preventDefault()
      setControlKeyPressed(true);
      setTimeout(() => {
        setControlKeyPressed(false);
      }, 1000);
    }
  });

  useEffect(() => {
    logRender({ type: "page", name: "assessment/Session" });
  }, []);

  // async check image
  const loadImage = async (image: HTMLImageElement, totalLoad: number): Promise<number> => {
    return new Promise((resolve, reject) => {
      totalLoad++;
      image.onload = () => {
        resolve(totalLoad);
      };
      image.onerror = () => {
        reject(`Image not found, Question Image: ${totalLoad}`)
      };
    });
  }

  const loadQuestionary = useCallback(async (assessment_participant_session: TPPAssessmentParticipantSession | undefined) => {
    try {
      if (assessment_participant_session) {
        // check if assessment session is completed / done
        if ((assessment_participant_session.remaining_time ?? 0) >= 0) {
          // CFIT image question
          if (assessment_participant_session.type_test === "cfit") {
            // set questionary is with image (cfit default questionary is image)
            setQuestionaryWithImageLoad(true)
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resQusetionaryCfit = await psc.getQuestionaryCfit(assessment_participant_session.id);
            // if return empty array
            if (resQusetionaryCfit.data.list.length === 0) {
              // generate questionary
              await psc.createQuestionaryCfit(assessment_participant_session.id);
            }
            // re-fetch questionary data
            resQusetionaryCfit = await psc.getQuestionaryCfit(assessment_participant_session.id);
            setImageQuestionaryTotal(resQusetionaryCfit.data.list.length)
            for (let index = 0, totalLoad = 0; index < resQusetionaryCfit.data.list.length; index++) {
              const questionary: TPPAssessmentSessionCfitQuestionary = resQusetionaryCfit.data.list[index];
              const image = new Image();
              image.src = "/upload/psikotest_participant/get_cfit/" + questionary.cfit_questionary.question_image;
              totalLoad = await loadImage(image, totalLoad)
              setImageQuestionaryTotalLoaded(totalLoad)
            }
            // reset total
            setImageQuestionaryTotalLoaded(0)
          }

          // IST image question
          if (assessment_participant_session.type_test === "ist") {
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resQuestionaryIst = await psc.getQuestionaryIst(assessment_participant_session.id);
            // if return empty array
            if (resQuestionaryIst.data.list.length === 0) {
              // generate questionary
              await psc.createQuestionaryIst(assessment_participant_session.id);
            }
            // re-fetch questionary data
            resQuestionaryIst = await psc.getQuestionaryIst(assessment_participant_session.id);
            // check if questionary is avaible
            if (resQuestionaryIst.data.list.length > 0) {
              // check if questionary is image
              const withImage = resQuestionaryIst.data.list[0]?.ist.with_mdi_question_image === 1
              if (withImage) {
                // set questionary is with image
                setQuestionaryWithImageLoad(true)
                setImageQuestionaryTotal(resQuestionaryIst.data.list.length)
                for (let index = 0, totalLoad = 0; index < resQuestionaryIst.data.list.length; index++) {
                  const questionary: TPPAssessmentSessionIstQuestionary = resQuestionaryIst.data.list[index];
                  const image_questionary = new Image();
                  image_questionary.src = "/upload/psikotest_participant/get_ist_questionary_image/" + questionary.ist_questionary_image.ist_questionary_image;
                  await loadImage(image_questionary, totalLoad)
                  const image_question = new Image();
                  image_question.src = "/upload/psikotest_participant/get_ist_questionary/" + questionary.ist_questionary.question;
                  totalLoad = await loadImage(image_question, totalLoad)
                  setImageQuestionaryTotalLoaded(totalLoad)
                }
                // reset total
                setImageQuestionaryTotalLoaded(0)
              }
            }
          }

          // RAC image question
          if (assessment_participant_session.type_test === "RAC") {
            // set questionary is with image (rac default questionary is image)
            setQuestionaryWithImageLoad(true)
            // get all question in subtest id
            const psc = new ParticipantSession();
            let resQusetionaryRac = await psc.getQuestionaryRac();
            setImageQuestionaryTotal(resQusetionaryRac.data.list.length)
            for (let index = 0, totalLoad = 0; index < resQusetionaryRac.data.list.length; index++) {
              const questionary: TPPARacQuestionary = resQusetionaryRac.data.list[index];
              const image = new Image();
              image.src = "/upload/psikotest_participant/get_rac/" + questionary.question_image;
              totalLoad = await loadImage(image, totalLoad)
              setImageQuestionaryTotalLoaded(totalLoad)
            }
            // reset total
            setImageQuestionaryTotalLoaded(0)
          }
        }

        // set questionary done load
        setQuestionaryWithImageLoad(false)
      }
    } catch (error) {
      // show notification
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
      // set load error
      setQuestionaryImageLoadFailed(errorMessage)
    }
  }, [setNotif])

  const getOpenSubtest = useCallback(async () => {
    try {
      // Get active session
      const psc = new ParticipantSession();
      const res = await psc.getOpenSubtest(assessment_participant?.access_key ?? "");
      // load quastionary
      loadQuestionary(res.data.row);
      // set assessment participant session
      if (
        // Intelligence
        res.data.row.type_test === "cfit"
        || res.data.row.type_test === "ist"
        // Personality
        || res.data.row.type_test === "MBTI"
        || res.data.row.type_test === "PAPI"
        || res.data.row.type_test === "EPPS"
        || res.data.row.type_test === "SRQ-20"
        // Behavioural
        || res.data.row.type_test === "RAC"
        || res.data.row.type_test === "DISC"
        || res.data.row.type_test === "RESILIENCE"
      ) {
        setAssessmentParticipantSession(res.data.row)
      }

      // set snap interval
      if (res.data.row.snap_interval_assessment > 0) {
        setIntervalGetImage(res.data.row.snap_interval_assessment)
      }
      setCloseByUser(false)
    } catch (error) {
      // no notification here we avoid conflict message from websocket
      // const errorMessage = errorHandler(error);
      // setNotif({ type: "error", message: errorMessage });
      // reset when assessment closed
      setAssessmentParticipantSession(undefined);
    }
    setIsCheckActiveSession(true);
  }, [assessment_participant.access_key, loadQuestionary]);

  // initial use effect get open session
  useEffect(() => {
    getOpenSubtest();
  }, [getOpenSubtest]);

  const getSession = useCallback(async () => {
    try {
      // make sure not undefined
      if (assessment_participant_session && assessment_participant_session.type_test) {
        const psc = new ParticipantSession();
        const res = await psc.getSession(assessment_participant_session.id, assessment_participant_session.type_test);
        setAssessmentParticipantSession({
          ...assessment_participant_session,
          start_time: res.data.row.start_time,
          end_time: res.data.row.end_time,
          remaining_time: res.data.row.remaining_time,
        })
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }, [assessment_participant_session, setNotif]);

  // set remaining time remaining
  useEffect(() => {
    if (assessment_participant_session?.remaining_time !== undefined && assessment_participant_session?.remaining_time !== null) {
      setTimeRemaining(assessment_participant_session.remaining_time)
    }
  }, [assessment_participant_session, setTimeRemaining]);


  // to make sure remaining time is correct
  // set remaining time context
  useEffect(() => {
    if (timeRemaining !== null && timeRemaining > 0) {
      if (Math.floor(timeRemaining % intervalCheckTimer) === 0) {
        console.log(new Date() + " check timer");
        getSession();
      }
    }
  }, [timeRemaining, getSession]);

  const startSession = async () => {
    try {
      // Start session test
      const psc = new ParticipantSession();
      // make sure not undefined
      if (assessment_participant_session && assessment_participant_session.type_test) {
        await psc.startSession(assessment_participant_session.id, assessment_participant_session.type_test);
      }
      getSession();
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const closeSession = async () => {
    try {
      const confirm = await Swal.fire({
        title: "Apakah anda yakin?",
        text: "Semua jawaban anda akan dikunci!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Kunci Jawaban!",
        cancelButtonText: "Batalkan",
      });
      if (confirm.isConfirmed) {
        // Start session test
        const psc = new ParticipantSession();
        // make sure not undefined
        if (assessment_participant_session && assessment_participant_session.type_test) {
          await psc.closeSession(assessment_participant_session.id, assessment_participant_session.type_test);
        }
        getSession();
        // for state close by user
        setCloseByUser(true);
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  }

  // counter to capt image webcam
  const getCamera = useCallback(async () => {
    if (timeRemaining !== null && timeRemaining > 0) {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setCameraAllowed(true);
      } catch (error) {
        setCameraAllowed(false);
      }
      setIsCheckCamera(true);
      // console.log(timeRemaining, intervalGetImage)
      if (Math.floor(timeRemaining % intervalGetImage) === 0) {
        if (webcamRef !== null && webcamRef.current !== null) {
          const image = webcamRef.current.getScreenshot();
          if (image !== null) {
            console.log(new Date(), "capt image");
            setCaptureImage(image);
          }
        }
      }
    } else {
      const stream = webcamRef.current?.stream
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    }
  }, [timeRemaining, intervalGetImage]);

  // check camera is open when remaining time set
  useEffect(() => {
    getCamera();
  }, [getCamera]);

  // upload capture image
  useEffect(() => {
    const uploadCapturedImage = async () => {
      if (captureImage !== undefined) {
        try {
          // upload webcam
          // make sure not undefined
          if (assessment_participant_session && assessment_participant_session.type_test) {
            const psc = new ParticipantSession();
            await psc.saveWebcam(assessment_participant_session.id, assessment_participant_session.type_test, {
              base64_image: captureImage,
            });
          }
        } catch (error) {
          const errorMessage = errorHandler(error);
          setNotif({ type: "error", message: errorMessage });
        }
      }
    };
    uploadCapturedImage();
  }, [captureImage, setNotif, assessment_participant_session]);

  return (
    <div className="print:hidden w-screen h-screen flex flex-col overflow-hidden">
      {printScreen && (
        <div className="w-screen h-screen fixed top-0 left-0 bg-white flex justify-center items-center z-50">
          Print Screen not Allowed
        </div>
      )}
      {controlKeyPressed && (
        <div className="w-screen h-screen fixed top-0 left-0 bg-white flex justify-center items-center z-50">
          Control Key not Allowed
        </div>
      )}
      <Header />
      {/* check active session */}
      {isCheckActiveSession === false ? (
        <div className="h-full flex flex-col justify-center items-center">
          <div className="font-light text-lg">Cek Sesi Aktif</div>
        </div>
      ) : (
        <>
          {/* If no session active (open) */}
          {assessment_participant_session === undefined ? (
            <div className="h-full flex flex-col justify-center items-center">
              <div className="font-semibold text-xl">Belum ada sesi dibuka</div>
              <div className="font-light text-lg">Mohon tunggu dan ikuti arahan assessor</div>
            </div>
          ) : (
            <>
              {/* if session active (open) */}
              {/* check if user not start the session (start_time === null)*/}
              {assessment_participant_session.start_time === null ? (
                <div className="h-full flex flex-col justify-center items-center">
                  {questionaryImageLoadFailed !== ""
                    ? <>
                      <div>Loading Questionary Failed</div>
                      <div>{questionaryImageLoadFailed}</div>
                    </>
                    : <>
                      {questionaryWithImageLoad === undefined
                        ? <div>Check Questionary</div>
                        : <>
                          {questionaryWithImageLoad === true
                            ? <>
                              <div>Please Wait</div>
                              <div>Loading Questionary Image</div>
                              <div>{imageQuestionaryTotalLoaded + " / " + imageQuestionaryTotal}</div>
                            </>
                            : <>
                              <div className="font-semibold text-xl">
                                Apakah anda sudah siap ?
                              </div>
                              <button
                                type="button"
                                className="mt-4 first-letter:items-center px-4 py-2 border border-blue-500 text-base leading-6 font-medium rounded-full text-blue-600 bg-white hover:bg-blue-600 hover:text-white focus:border-blue-400"
                                onClick={() => startSession()}
                              >
                                <span>Ya, Mulai!</span>
                              </button>
                            </>
                          }
                        </>
                      }
                    </>
                  }

                </div>
              ) : (
                <div className="bg-white">
                  <Webcam
                    width={200}
                    height={200}
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className="-z-10 absolute"
                    videoConstraints={{ facingMode: "user" }}
                  />
                  {/* here the user has been start the assessment session */}
                  {/* check if times up */}
                  {timeRemaining !== null && timeRemaining > 0 && (
                    <>
                      {/* loading for checking camera */}
                      {isCheckCamera ? (
                        <>
                          {/* Check if camera on */}
                          {cameraAllowed ? (
                            <div className="h-screen">
                              {assessment_participant_session !== undefined && (
                                <>
                                  {/** Intelligence */}
                                  {assessment_participant_session.type_test === "cfit" &&
                                    <SubtestCfit assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                  {assessment_participant_session.type_test === "ist" &&
                                    <SubtestIst assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                  {/** Personality */}
                                  {assessment_participant_session.type_test === "MBTI" &&
                                    <SubtestMbti assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                  {assessment_participant_session.type_test === "PAPI" &&
                                    <SubtestPapi assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                  {assessment_participant_session.type_test === "EPPS" &&
                                    <SubtestEpps assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                  {assessment_participant_session.type_test === "SRQ-20" &&
                                    <SubtestSrq20 assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                  {/** Behavioural */}
                                  {assessment_participant_session.type_test === "RAC" &&
                                    <SubtestRac assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                  {assessment_participant_session.type_test === "DISC" &&
                                    <SubtestDisc assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                  {assessment_participant_session.type_test === "RESILIENCE" &&
                                    <SubtestResilience assessment_participant_session={assessment_participant_session} closeSession={closeSession} />
                                  }
                                </>
                              )}
                            </div>
                          ) : (
                            <div className="h-screen flex flex-col justify-center items-center">
                              <div className="font-semibold text-xl">Akses kamera tidak di izinkan</div>
                              <div className="font-light text-lg text-center">
                                Akses kamera diperlukan untuk melanjutkan proses assessment, mohon berikan izin akses
                                kamera kepada halaman ini.
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="h-screen flex flex-col justify-center items-center">
                          <div className="font-light text-lg text-center">Check Kamera</div>
                        </div>
                      )}
                    </>
                  )}
                  {timeRemaining !== null && timeRemaining <= 0 && (
                    <div className="h-screen flex flex-col justify-center items-center">
                      {close_by_user
                        ? <div className="font-semibold text-xl">Test telah diselesaikan</div>
                        : <div className="font-semibold text-xl">Waktu anda habis</div>
                      }
                      <div className="font-light text-lg">Mohon tunggu dan ikuti arahan assessor</div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Session;
