import { useContext } from "react";
import { NavLink } from "react-router-dom";

// Icons
import { BuildingOffice2Icon, ClipboardDocumentListIcon, CheckBadgeIcon, HomeIcon, BeakerIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

// Context
import PSAuthContext from "../../../context/psikotest/AuthContext";

function MenuDesktop() {
  const { user } = useContext(PSAuthContext);

  return (
    <nav className="block">
      <ul className="space-y-0.5">
        <li className="flex flex-col">
          <div className="px-2 py-2 text-xs font-semibold">MENU</div>
        </li>
        {/** Dashboard */}
        <li className="group flex flex-col">
          <NavLink
            to={``}
            end
            className={({ isActive }) =>
              `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
              } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
            }
            children={({ isActive }) => (
              <div className="flex">
                <HomeIcon
                  className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                    } w-5 h-5 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Dashboard
              </div>
            )}
          />
        </li>
        {/** Assessment */}
        <li className="group flex flex-col">
          <NavLink
            to={`assessment`}
            className={({ isActive }) =>
              `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
              } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
            }
            children={({ isActive }) => (
              <div className="flex">
                <CheckBadgeIcon
                  className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                    } w-5 h-5 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Assessment
              </div>
            )}
          />
        </li>
        {/** Company - Admin Only */}
        {user.roles === "1" && (
          <li className="group flex flex-col">
            <NavLink
              to={`company`}
              className={({ isActive }) =>
                `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
              }
              children={({ isActive }) => (
                <div className="flex">
                  <BuildingOffice2Icon
                    className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                      } w-5 h-5 mr-2 fill-tranparent`}
                    aria-hidden="true"
                  />
                  Company
                </div>
              )}
            />
          </li>
        )}
        {/** Questionary - Admin Only */}
        {user.roles === "1" && (
          <>
            {/** Questionary Sub Menu */}
            <li className="flex flex-col items-center">
              <div className="flex px-2 pt-2 text-xs font-semibold text-center">
                <ClipboardDocumentListIcon
                  className={`stroke-gray-700 w-4 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Questionary
              </div>
            </li>
            {/** Intelligence Sub Menu */}
            <li className="flex flex-col">
              <div className="px-2 text-xs font-normal">Intelligence</div>
            </li>
            {/** Intelligence - CFIT */}
            <li className="group flex flex-col">
              <NavLink
                to={`cfit`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    CFIT Subtest
                  </div>
                )}
              />
            </li>
            {/** Intelligence - IST */}
            <li className="group flex flex-col">
              <NavLink
                to={`ist`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    IST Subtest
                  </div>
                )}
              />
            </li>
            {/** Personality Sub Menu */}
            <li className="flex flex-col">
              <div className="px-2 text-xs font-normal">Personality</div>
            </li>
            {/** Personality - MBTI */}
            <li className="group flex flex-col">
              <NavLink
                to={`mbti_questionary`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    MBTI Questionary
                  </div>
                )}
              />
            </li>
            {/** Personality - PAPI */}
            <li className="group flex flex-col">
              <NavLink
                to={`papi_questionary`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    PAPI Questionary
                  </div>
                )}
              />
            </li>
            {/** Personality - EPPS */}
            <li className="group flex flex-col">
              <NavLink
                to={`epps_questionary`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    EPPS Questionary
                  </div>
                )}
              />
            </li>
            {/** Personality - SRQ-20 */}
            <li className="group flex flex-col">
              <NavLink
                to={`srq20_questionary`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    SRQ-20 Questionary
                  </div>
                )}
              />
            </li>
            {/** Behavioural Sub Menu */}
            <li className="flex flex-col">
              <div className="px-2 text-xs font-normal">Behavioural</div>
            </li>
            {/** Behavioural - RAC */}
            <li className="group flex flex-col">
              <NavLink
                to={`rac_questionary`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    RAC Questionary
                  </div>
                )}
              />
            </li>
            {/** Behavioural - DISC */}
            <li className="group flex flex-col">
              <NavLink
                to={`disc_questionary`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    DISC Questionary
                  </div>
                )}
              />
            </li>
            {/** Behavioural - RESILIENCE */}
            <li className="group flex flex-col">
              <NavLink
                to={`resilience_questionary`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    RESILIENCE Questionary
                  </div>
                )}
              />
            </li>
          </>
        )}
        {/** Formula - Admin Only */}
        {user.roles === "1" && (
          <>
            {/** Formula Sub Menu */}
            <li className="flex flex-col items-center">
              <div className="flex px-2 pt-2 text-xs font-semibold text-center">
                <BeakerIcon
                  className={`stroke-gray-700 w-4 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Formula
              </div>
            </li>
            {/** Intelligence Sub Menu */}
            <li className="flex flex-col">
              <div className="px-2 text-xs font-normal">Intelligence</div>
            </li>
            {/** Formula - CFIT Formula */}
            <li className="group flex flex-col">
              <NavLink
                to={`cfit_formula`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    CFIT Formula
                  </div>
                )}
              />
            </li>
            {/** Formula - IST Formula */}
            <li className="group flex flex-col">
              <NavLink
                to={`ist_formula`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    IST Formula
                  </div>
                )}
              />
            </li>
            {/** Personality Sub Menu */}
            <li className="flex flex-col">
              <div className="px-2 text-xs font-normal">Personalty</div>
            </li>
            {/** Formula - EPPS Formula Norma */}
            <li className="group flex flex-col">
              <NavLink
                to={`epps_formula_norma`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    EPPS Formula Norma
                  </div>
                )}
              />
            </li>
            {/** Behavioural Sub Menu */}
            <li className="flex flex-col">
              <div className="px-2 text-xs font-normal">Behavioural</div>
            </li>
            {/** Formula - RAC Formula Norma */}
            <li className="group flex flex-col">
              <NavLink
                to={`rac_formula_norma`}
                className={({ isActive }) =>
                  `${isActive ? "bg-blue-500 text-white" : "text-gray-900"
                  } flex items-center font-semibold w-full px-2 py-2 text-sm border rounded group-hover:bg-blue-300 group-hover:text-white`
                }
                children={({ isActive }) => (
                  <div className="flex">
                    RAC Formula Norma
                  </div>
                )}
              />
            </li>
          </>
        )}
        {/** Other Links */}
        <li className="flex flex-col">
          <div className="flex px-2 pt-2 text-xs font-semibold text-center uppercase mb-2">
            Other Links
          </div>
        </li>
        <li className="group flex flex-col">
          <NavLink
            to={`/assessment_system`}
            target="_blank"
            className={`text-gray-900 flex items-center font-semibold w-full px-2 py-2 text-sm border rounded bg-red-50 group-hover:bg-red-500 group-hover:text-white`}
            children={({ isActive }) => (
              <div className="flex">
                <ArrowTopRightOnSquareIcon
                  className={`${isActive ? "stroke-white" : "stroke-gray-700 group-hover:stroke-white"
                    } w-5 h-5 mr-2 fill-tranparent`}
                  aria-hidden="true"
                />
                Assessment System
              </div>
            )}
          />
        </li>
      </ul>
    </nav>
  );
}

export default MenuDesktop;
