import reqNoAuth from "../../../services/requestNoAuth";

class Session {
  async getOpenSubtest(access_key: string) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_open_subtest/" + access_key);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async startSession(assessment_participant_session_id: number, type_test: string) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/start_session/" + assessment_participant_session_id + "/" + type_test);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getSession(assessment_participant_session_id: number, type_test: string) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_session/" + assessment_participant_session_id + "/" + type_test);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async closeSession(assessment_participant_session_id: number, type_test: string) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/close_session/" + assessment_participant_session_id + "/" + type_test);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryCfit(assessment_participant_cfit_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_questionary_cfit/" + assessment_participant_cfit_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async createQuestionaryCfit(assessment_participant_cfit_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/create_questionary_cfit/" + assessment_participant_cfit_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async answerQuestionaryCfit(data: TPPAssessmentSessionCfitQuestionary) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/answer_questionary_cfit/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryIst(assessment_participant_ist_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_questionary_ist/" + assessment_participant_ist_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async createQuestionaryIst(assessment_participant_ist_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/create_questionary_ist/" + assessment_participant_ist_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async answerQuestionaryIst(data: TPPAssessmentSessionIstQuestionary) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/answer_questionary_ist/" + data.id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryMbti() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_questionary_mbti");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryAnswerMbti(assessment_participant_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_answer_questionary_mbti/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveQuestionaryAnswerMbti(assessment_participant_session_id: number, data: TPSAssessmentParticipantMbtiSessionAnswer) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/save_answer_questionary_mbti/" + assessment_participant_session_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryPapi() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_questionary_papi");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryAnswerPapi(assessment_participant_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_answer_questionary_papi/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveQuestionaryAnswerPapi(assessment_participant_session_id: number, data: TPSAssessmentParticipantPapiSessionAnswer) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/save_answer_questionary_papi/" + assessment_participant_session_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryEpps() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_questionary_epps");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryAnswerEpps(assessment_participant_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_answer_questionary_epps/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveQuestionaryAnswerEpps(assessment_participant_session_id: number, data: TPSAssessmentParticipantEppsSessionAnswer) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/save_answer_questionary_epps/" + assessment_participant_session_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getSrq20() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_srq20");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionarySrq20() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_questionary_srq20");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryAnswerSrq20(assessment_participant_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_answer_questionary_srq20/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveQuestionaryAnswerSrq20(assessment_participant_session_id: number, data: TPSAssessmentParticipantSrq20SessionAnswer) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/save_answer_questionary_srq20/" + assessment_participant_session_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getRac() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_rac");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryRac() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_questionary_rac");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryAnswerRac(assessment_participant_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_answer_questionary_rac/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveQuestionaryAnswerRac(
    assessment_participant_session_id: number,
    data: TPSAssessmentParticipantRacSessionAnswer & { number: number, answer_selected: string, update: boolean }
  ) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/save_answer_questionary_rac/" + assessment_participant_session_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getDisc() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_disc");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryDisc() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_questionary_disc");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryAnswerDisc(assessment_participant_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_answer_questionary_disc/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveQuestionaryAnswerDisc(
    assessment_participant_session_id: number,
    data: TPSAssessmentParticipantRacSessionAnswer
  ) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/save_answer_questionary_disc/" + assessment_participant_session_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getResilience() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_resilience");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getDistractionResilience() {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_distraction_resilience");
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async getQuestionaryAnswerResilience(assessment_participant_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/get_answer_questionary_resilience/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveQuestionaryAnswerResilience(assessment_participant_session_id: number, data: TPSAssessmentParticipantResilienceSessionAnswer) {
    try {
      const res = await reqNoAuth.post("/api/psikotest_participant/session/save_answer_questionary_resilience/" + assessment_participant_session_id, data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async updateResilienceSession(assessment_participant_session_id: number) {
    try {
      const res = await reqNoAuth.get("/api/psikotest_participant/session/update_resilience_session/" + assessment_participant_session_id);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  async saveWebcam(assessment_participant_session_id: number, type_test: string, data: { base64_image: string }) {
    try {
      const res = await reqNoAuth.post(
        "/api/psikotest_participant/session/save_webcam/" + assessment_participant_session_id + "/" + type_test,
        data
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default Session;
