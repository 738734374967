import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState, useContext, useCallback, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Dialog, Disclosure, Transition } from "@headlessui/react";

import {
  ArrowDownTrayIcon,
  TrashIcon,
  ChevronUpIcon,
  DocumentMagnifyingGlassIcon,
  BoltIcon,
  QuestionMarkCircleIcon,
  ChevronDoubleRightIcon,
  StarIcon,
  ClipboardDocumentCheckIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

// Context
import AuthContext from "../../../context/assessment/AuthContext";
import LoaderContext from "../../../context/assessment/LoaderContext";
import SnackbarContext from "../../../context/assessment/SnackbarContext";

// Controller
import Company from "../../../controller/assessment/assessment_system/company";
import Assessor from "../../../controller/assessment/assessment_system/assessor";
import Assessment from "../../../controller/assessment/assessment_system/assessment";
import Category from "../../../controller/assessment/assessment_system/category";
import Questionary from "../../../controller/assessment/assessment_system/questionary";

// Helper
import logRender from "../../../helper/assessment/logRender";
import errorHandler from "../../../helper/assessment/errorHandler";

// Component
import BackButton from "../../../components/assessment/assessment_system/BackButton";
import FormSession from "./FormSession";
import FormParticipant from "./FormParticipant";
import FormScoreName from "./FormScoreName";
import FormParticipantScore from "./FormParticipantScore";
import FormParticipantSession from "./FormParticipantSession";
import FormParticipantSessionPanel from "./FormParticipantSessionPanel";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  id: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  company_id: yup
    .number()
    .label("Company")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  title: yup.string().label("Title").required(),
  date: yup.string().label("Date").required(),
  start_time: yup.string().label("Title").required(),
  pic_id: yup
    .number()
    .label("PIC Assessor")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  qc_id: yup
    .number()
    .label("QC Assessor")
    .transform((value) => (isNaN(value) ? undefined : value))
    .required(),
  link_zoom: yup.string().url().label("Link Zoom").required(),
  wa_number: yup.string().matches(phoneRegExp, "Phone number is not valid").label("WA Number").required(),
  //status
});

function Form() {
  // context
  const { user } = useContext(AuthContext);
  const { setMessage } = useContext(LoaderContext);
  const { setNotif } = useContext(SnackbarContext);
  // is admin
  const isAdmin = user.roles === "1";
  //
  const navigate = useNavigate();
  // get param id
  const location = useLocation();
  // remove trailing slash in production when reload/refresh
  const arrPath = location.pathname.replace(/\/$/, "").split("/");
  const paramId = arrPath[arrPath.length - 1];
  // is create
  const isCreate = paramId === "create";
  // state required value
  const [companyOptions, setCompanyOptions] = useState<TSelect[]>([]);
  const [assessorOptions, setAssessorOptions] = useState<TSelect[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<TSelect[]>([]);
  const [questionaryOptions, setQuestionaryOptions] = useState<TSelect[]>([]);
  // state document
  const [doc, setDoc] = useState<TAssessment>({
    id: 0,
    title: "",
    company_id: 0,
    date: "",
    start_time: "",
    pic_id: 0,
    qc_id: 0,
    link_zoom: "",
    wa_number: "",
    status: "0",
  });
  // state helper document
  const [id, setId] = useState<number>(0);
  // state modal
  const [isOpenParticipantScore, setIsOpenParticipantScore] = useState<boolean>(false);
  const [isOpenParticipantSession, setIsOpenParticipantSession] = useState<boolean>(false);
  const [isOpenParticipantSessionPanel, setIsOpenParticipantSessionPanel] = useState<TAssessmentSession | undefined>(undefined);
  const [isOpenStatus, setIsOpenStatus] = useState<boolean>(false);
  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<TAssessment>({ resolver: yupResolver(schema) });

  useEffect(() => {
    logRender({ type: "page", name: "assessment/Form" });
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpenParticipantScore || isOpenParticipantSession || isOpenParticipantSessionPanel !== undefined ? "hidden" : "auto";
  }, [isOpenParticipantScore, isOpenParticipantSession, isOpenParticipantSessionPanel]);

  const getDoc = useCallback(
    async function getDoc() {
      try {
        // fetch company
        setMessage("Fetch Company");
        const cc = new Company();
        const resCompany = await cc.list();
        const toSelectCompany: TSelect[] = resCompany.data.list
          .filter((v: TCompany) => v.company_name !== null)
          .map((v: TCompany) => {
            return { id: v.id, label: `${v.company_name} - ${v.level}` };
          });
        setCompanyOptions(toSelectCompany);
        // fetch category
        setMessage("Fetch Category");
        const ctc = new Category();
        const resCategory = await ctc.list();
        const toSelectCategory: TSelect[] = resCategory.data.list
          .filter((v: TCategory) => v.category !== null)
          .map((v: TCategory) => {
            return { id: v.id, label: v.category };
          });
        setCategoryOptions(toSelectCategory);
        // fetch questionary
        setMessage("Fetch Questionary");
        const qc = new Questionary();
        const resQuestionary = await qc.list();
        const toSelectQuestionary: TSelect[] = resQuestionary.data.list
          .filter((v: TQuestionary) => v.description !== null)
          .map((v: TQuestionary) => {
            return { id: v.id, label: v.description, unique_key: v.category_id };
          });
        setQuestionaryOptions(toSelectQuestionary);
        // fetch assessor
        setMessage("Fetch Assessor");
        const ac = new Assessor();
        const resAssessor = await ac.list();
        const toSelectAssessor: TSelect[] = resAssessor.data.list.map((v: TAssessorDetail) => {
          return { id: v.id, label: v.full_name };
        });
        setAssessorOptions(toSelectAssessor);
        if (!isCreate) {
          // if create or new assessment
          // fetch main document
          setMessage("Fetch Document");
          const ac = new Assessment();
          const res = await ac.get(paramId);
          const row: TAssessment = res.data.row;
          // update status if null
          row.status = row.status === null ? "0" : row.status;
          setDoc(row);
          // make sure file upload components updated current document
          setId(row.id);
          setValue("id", row.id);
          setValue("company_id", row.company_id);
          setValue("title", row.title);
          setValue("pic_id", row.pic_id);
          setValue("qc_id", row.qc_id);
          setValue("date", row.date);
          setValue("start_time", row.start_time);
          setValue("link_zoom", row.link_zoom);
          setValue("wa_number", row.wa_number);
          setValue("status", row.status);
        } else {
          // if create mode
          setValue("status", "0");
        }
        setMessage("");
      } catch (error) {
        setMessage("");
        const errorMessage = errorHandler(error);
        setNotif({ type: "error", message: errorMessage });
        navigate(`../`, { replace: true });
      }
    },
    [setValue, paramId, isCreate, setNotif, setMessage, navigate]
  );

  useEffect(() => {
    getDoc();
  }, [getDoc]);

  const onSubmit: SubmitHandler<TAssessment> = async (data) => {
    try {
      setMessage("Save Assessment");
      const ac = new Assessment();
      let res;
      if (isCreate) {
        res = await ac.create(data);
      } else {
        res = await ac.update(id.toString(), data);
      }
      setNotif({ type: "success", message: res.data.message });
      setMessage("");
      if (isCreate) {
        navigate(`../${res.data.result.saved_id}`, { replace: true });
      } else {
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      setMessage("");
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  useEffect(() => {
    setValue("id", id);
  }, [setValue, id]);

  const confirmDelete = async (assessment: TAssessment) => {
    try {
      const confirm = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (confirm.isConfirmed) {
        const ac = new Assessment();
        await ac.delete(assessment.id.toString());
        setNotif({ type: "success", message: "assessment deleted" });
        navigate(`../`, { replace: true });
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setNotif({ type: "error", message: errorMessage });
    }
  };

  const processAssessement = async () => {
    const confirm = await Swal.fire({
      title: statusLabel(doc.status).title_process_action,
      text: statusLabel(doc.status).text_process_action,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    });
    if (confirm.isConfirmed) {
      switch (doc.status) {
        case "0":
          setValue("status", "1");
          break;
        case "1":
          setValue("status", "2");
          break;
        case "2":
          setValue("status", "3");
          break;
        case "3":
          setValue("status", "99");
          break;
        default:
          break;
      }
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className="w-full">
      {/* Participant Session Form */}
      <Transition
        as={Fragment}
        show={isOpenParticipantSession}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormParticipantSession
            doc={doc}
            assessorOptions={assessorOptions}
            setIsOpenParticipantSession={(close) => setIsOpenParticipantSession(close)}
          />
        </div>
      </Transition>
      {/* Score Form */}
      <Transition
        as={Fragment}
        show={isOpenParticipantScore}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormParticipantScore doc={doc} setIsOpenParticipantScore={(close) => setIsOpenParticipantScore(close)} />
        </div>
      </Transition>
      {/* Participant Session Panel */}
      <Transition
        as={Fragment}
        show={isOpenParticipantSessionPanel !== undefined}
        enter="ease-out duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-50"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          <FormParticipantSessionPanel
            doc={doc}
            assessorOptions={assessorOptions}
            isOpenParticipantSessionPanel={isOpenParticipantSessionPanel}
            setIsOpenParticipantSessionPanel={(close) => setIsOpenParticipantSessionPanel(close)}
          />
        </div>
      </Transition>
      {/* Status Modal */}
      <StatusModal isOpen={isOpenStatus} setIsOpenStatus={(close) => setIsOpenStatus(close)} />
      {/* Button Action */}
      <div className="flex sticky top-[6.5rem] py-1 bg-white z-10">
        <BackButton />
        {isAdmin && (
          <button
            onClick={handleSubmit(onSubmit)}
            className="mx-2 block w-auto px-4 py-2 bg-green-500 hover:bg-green-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <ArrowDownTrayIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Save
            </div>
          </button>
        )}
        {!isCreate && isAdmin && (
          <button
            onClick={() => confirmDelete(doc)}
            className="ml-auto block w-auto px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded font-semibold text-sm"
          >
            <div className="flex">
              <TrashIcon className="w-5 h-5 mr-2 stroke-white fill-tranparent" aria-hidden="true" />
              Delete
            </div>
          </button>
        )}
      </div>
      {/* MAIN SECTION */}
      <div className="pt-4">
        {/* Status Assessment, Info & Process */}
        <div className="flex flex-col md:flex-row justify-between w-full mb-2">
          <div className="flex items-center w-full md:w-fit mb-1">{statusLabel(doc.status).AssesmentLabel}</div>
          <div className="flex ml-auto">
            {isAdmin && (
              <button
                onClick={() => setIsOpenStatus(true)}
                className="block w-auto p-2 bg-gray-500 hover:bg-gray-700 text-white rounded font-semibold text-sm"
              >
                <QuestionMarkCircleIcon className="w-5 h-5 stroke-white fill-tranparent" aria-hidden="true" />
              </button>
            )}
            {!isCreate && isAdmin && doc.status !== "99" && (
              <button
                onClick={() => processAssessement()}
                className="ml-2 block w-auto px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded font-semibold text-sm"
              >
                <div className="flex">
                  {statusLabel(doc.status).process_button_label}
                  <ChevronDoubleRightIcon className="w-5 h-5 ml-2 stroke-white fill-tranparent" aria-hidden="true" />
                </div>
              </button>
            )}
          </div>
        </div>
        {/* Assessment Main Form */}
        <Disclosure defaultOpen={true} as="div" className="pb-1">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-900 rounded hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>Assessment</span>
                <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                  <form className="w-full">
                    <div className="flex flex-wrap -mx-3">
                      <input {...register("id")} type="number" className="hidden" />
                      <input {...register("status")} type="text" className="hidden" />
                      {/* Company */}
                      <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Company
                        </label>
                        <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                          <select
                            className={
                              (errors?.company_id ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                              " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                            }
                            disabled={!isAdmin}
                            defaultValue=""
                            {...register("company_id")}
                          >
                            <option value="" disabled>
                              Please Select
                            </option>
                            {companyOptions.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.company_id && <p className="text-red-500 text-xs italic">{errors.company_id.message}</p>}
                      </div>
                      {/* Assessment Title */}
                      <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Assessment Title
                        </label>
                        <input
                          className={
                            (errors?.title ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                            " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                          }
                          disabled={!isAdmin}
                          {...register("title")}
                        />
                        {errors.title && <p className="text-red-500 text-xs italic">{errors.title.message}</p>}
                      </div>
                      {/* PIC Assessor */}
                      <div className="w-full md:w-1/2 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          PIC <i className="font-semibold normal-case">(Assessor)</i>
                        </label>
                        <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                          <select
                            className={
                              (errors?.pic_id ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                              " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                            }
                            disabled={false}
                            defaultValue=""
                            {...register("pic_id")}
                          >
                            <option value="" disabled>
                              Please Select
                            </option>
                            {assessorOptions.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.pic_id && <p className="text-red-500 text-xs italic">{errors.pic_id.message}</p>}
                      </div>
                      {/* QC Assessor */}
                      <div className="w-full md:w-1/2 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          QC <i className="font-semibold normal-case">(Assessor)</i>
                        </label>
                        <div className="relative after:content-['↓'] after:absolute after:right-4 after:top-3 after:pointer-events-none">
                          <select
                            className={
                              (errors?.qc_id ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                              " appearance-none first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-800"
                            }
                            disabled={!isAdmin}
                            defaultValue=""
                            {...register("qc_id")}
                          >
                            <option value="" disabled>
                              Please Select
                            </option>
                            {assessorOptions.map((v, i) => (
                              <option key={i} value={v.id}>
                                {v.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.qc_id && <p className="text-red-500 text-xs italic">{errors.qc_id.message}</p>}
                      </div>
                      {/* Date */}
                      <div className="w-full md:w-1/2 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Date
                        </label>
                        <input
                          type="date"
                          className={
                            (errors.date ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                            " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                          }
                          disabled={!isAdmin}
                          {...register("date")}
                        />
                        {errors.date && <p className="text-red-500 text-xs italic">{errors.date.message}</p>}
                      </div>
                      {/* Start Time */}
                      <div className="w-full md:w-1/2 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Start Time
                        </label>
                        <input
                          type="time"
                          className={
                            (errors.start_time ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                            " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                          }
                          disabled={!isAdmin}
                          {...register("start_time")}
                        />
                        {errors.start_time && (
                          <p className="text-red-500 text-xs italic">{errors.start_time.message}</p>
                        )}
                      </div>
                      {/* Link Zoom */}
                      <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Link Zoom
                        </label>
                        <input
                          className={
                            (errors?.link_zoom ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                            " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                          }
                          disabled={!isAdmin}
                          {...register("link_zoom")}
                        />
                        {errors.link_zoom && <p className="text-red-500 text-xs italic">{errors.link_zoom.message}</p>}
                      </div>
                      {/* WA Number */}
                      <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          WA Number (Admin)
                          <br />
                          <i className="font-normal normal-case">Start With Country Code, ex : 62 (Indonesia)</i>
                        </label>
                        <input
                          className={
                            (errors?.wa_number ? "border-red-500" : "border-gray-200 focus:border-gray-500") +
                            " first-letter:appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2 px-3 focus:outline-none focus:bg-white disabled:text-gray-500"
                          }
                          disabled={!isAdmin}
                          {...register("wa_number")}
                        />
                        {errors.wa_number && <p className="text-red-500 text-xs italic">{errors.wa_number.message}</p>}
                      </div>
                    </div>
                  </form>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
        {/* Session */}
        {!isCreate && (
          <Disclosure defaultOpen={false} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-900 rounded hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>Session</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm">
                    <FormSession
                      doc={doc}
                      isCreate={isCreate}
                      assessment_id={paramId}
                      editable={true}
                      categoryOptions={categoryOptions}
                      questionaryOptions={questionaryOptions}
                      assessorOptions={assessorOptions}
                      setIsOpenParticipantSessionPanel={(open) => setIsOpenParticipantSessionPanel(open)}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        )}
        {/* Participant */}
        {!isCreate && (
          <Disclosure defaultOpen={false} as="div" className="pb-1">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-900 rounded hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>Participant</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm">
                    <FormParticipant
                      doc={doc}
                      isCreate={isCreate}
                      assessment_id={paramId}
                      editable={true}
                      isOpenParticipantSession={isOpenParticipantSession}
                      setIsOpenParticipantSession={(open) => setIsOpenParticipantSession(open)}
                      setIsOpenParticipantScore={(open) => setIsOpenParticipantScore(open)}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        )}
        {/* Score Name (Competency) */}
        {!isCreate && (
          <Disclosure defaultOpen={false} as="div" className="pb-96">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-100 bg-gray-900 rounded hover:bg-gray-800 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                  <span>Competency</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-100`} />
                </Disclosure.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="pt-4 pb-2 text-sm">
                    <FormScoreName doc={doc} isCreate={isCreate} assessment_id={paramId} editable={true} />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        )}
      </div>
    </div>
  );
}

function statusLabel(status: "0" | "1" | "2" | "3" | "99") {
  let icon, label, process_button_label, title_process_action, text_process_action;
  switch (status) {
    case null:
      icon = <DocumentMagnifyingGlassIcon className="w-5" />;
      label = "DRAFT";
      process_button_label = "In Process";
      title_process_action = "Process Assessment ?";
      text_process_action = "make sure all data assessment draft is completed !";
      break;
    case "0":
      icon = <DocumentMagnifyingGlassIcon className="w-5" />;
      label = "DRAFT";
      process_button_label = "In Process";
      title_process_action = "Process Assessment ?";
      text_process_action = "make sure all data assessment draft is completed !";
      break;
    case "1":
      icon = <BoltIcon className="w-5" />;
      label = "ON PROGRESS";
      process_button_label = "Scoring";
      title_process_action = "Proceed to Scoring Assessment ?";
      text_process_action = "make sure all assessment session / process is completed !";
      break;
    case "2":
      icon = <StarIcon className="w-5" />;
      label = "SCORING";
      process_button_label = "Process Check";
      title_process_action = "Scoring completed ?";
      text_process_action = "make sure all assessment scoring is completed !";
      break;
    case "3":
      icon = <ClipboardDocumentCheckIcon className="w-5" />;
      label = "CHECKING";
      process_button_label = "Completed";
      title_process_action = "All correct and complete ?";
      text_process_action = "make sure all data is correct adn completed !";
      break;
    case "99":
      icon = <CheckCircleIcon className="w-5" />;
      label = "COMPLETED";
      process_button_label = "";
      title_process_action = "";
      text_process_action = "";
      break;
    default:
      icon = <QuestionMarkCircleIcon className="w-5" />;
      label = "UNKNOWN";
      process_button_label = "Unknown";
      title_process_action = "Unknown";
      text_process_action = "Unknown";
      break;
  }
  const AssesmentLabel = (
    <b className="flex bg-orange-100 py-2 px-4 rounded w-full border-l-2 border-gray-400 text-gray-700">
      {icon}
      <span className="block mx-1">Status Assessment : {label}</span>
    </b>
  );

  return {
    AssesmentLabel,
    process_button_label,
    title_process_action,
    text_process_action,
  };
}

function StatusModal(props: { isOpen: boolean; setIsOpenStatus: (close: boolean) => void }) {
  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={() => props.setIsOpenStatus(false)}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Draft
              </Dialog.Title>
              <div className="mt-2">
                <ul className="list-disc px-5 text-sm text-gray-500">
                  <li>Prepare all we need in the assessment.</li>
                </ul>
              </div>
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mt-2">
                In Process
              </Dialog.Title>
              <div className="mt-2">
                <ul className="list-decimal px-5 text-sm text-gray-500">
                  <li>Start with send access key to participant.</li>
                  <li>Click start in assessment session to start the assessment.</li>
                  <li>Click stop in assessment session when session end.</li>
                </ul>
              </div>
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mt-2">
                Scoring
              </Dialog.Title>
              <div className="mt-2">
                <ul className="list-disc px-5 text-sm text-gray-500">
                  <li>PR.</li>
                </ul>
              </div>
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mt-2">
                Completed
              </Dialog.Title>
              <div className="mt-2">
                <div className="mt-2">
                  <ul className="list-disc px-5 text-sm text-gray-500">
                    <li>PR.</li>
                  </ul>
                </div>
              </div>
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mt-2">
                Cancelled
              </Dialog.Title>
              <div className="mt-2">
                <div className="mt-2">
                  <ul className="list-disc px-5 text-sm text-gray-500">
                    <li>PR.</li>
                  </ul>
                </div>
              </div>

              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={() => props.setIsOpenStatus(false)}
                >
                  Got it, thanks!
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Form;
